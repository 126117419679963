const consts = {
    default_route: '/units',
    fetchAppProps: {
        dispatches: [
            {name: 'fetchUserSettings', params: {}},
            {name: 'fetchUnitsIcons', params: {}},
            {name: 'fetchUnitsHwTypesAll', params: {}},
            {name: 'fetchUnitsTypes', params: {}},
            {name: 'fetchProductsAll', params: {}},

            {name: 'fetchAppUser', params: {}},

            {name: 'fetchAppObjects', params: [
                {name: 'fetchUnitsAll', params: {}},
                {name: 'fetchUnitsLmsgsAll', params: {withAddress: 1}},
                {name: 'fetchVideoStatusesAll', params: {}},

                {name: 'fetchUsersAll', params: {}},
                //{name: 'fetchUsersCustomizesAll', params: {}},

                {name: 'fetchGeopointsAll', params: {}},
                {name: 'fetchGeozonesAll', params: {}},
                // {name: 'fetchNotificationsAll', params: {}},

                {name: 'fetchUnitsKeysAll', params: {'filter[free]': 1}},
            ]},

            {name: 'fetchNotificationsAll', params: {}},

            {name: 'fetchEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
            {name: 'fetchVideoEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
            {name: 'fetchNotificationsEvents4Date', params: {date: 'today', params: {withAddress: 1}}},
        ],
    },
    reloadAppProps: {
        dispatches: [
            {name: 'reloadUnitsAll', params: {}},
            {name: 'reloadUnitsLmsgsAll', params: {withAddress: 1}},
            {name: 'fetchVideoStatusesAll', params: {}},

            // {name: 'reloadUsersAll', params: {}},
            //{name: 'reloadUsersCustomizesAll', params: {}},

            // {name: 'reloadGeopointsAll', params: {}},
            // {name: 'reloadGeozonesAll', params: {}},

            // {name: 'reloadUnitsKeysAll', params: {'filter[free]': 1}},

            {name: 'reloadNotificationsAll', params: {}},

            // {name: 'reloadEvents', params: { params: {withAddress: 1}}},
            // {name: 'reloadVideoEvents', params: { params: {withAddress: 1}}},
            // {name: 'reloadNotificationsEvents', params: { params: {withAddress: 1}}},
        ],
        commits: [
            {name: 'clearTrips', params: {}},
            {name: 'clearParkings', params: {}},
            {name: 'clearTracks', params: {}},

            {name: 'clearEvents', params: {}},
            {name: 'clearVideoEvents', params: {}},
            {name: 'clearNotificationsEvents', params: {}},
            {name: 'clearStops', params: {}},

            {name: 'clearVideoRequests', params: {}},
            {name: 'clearVideoRecords', params: {}},
        ],
    },
    fetchByTimer: {
        dispatches: [
            //{name: 'fetchUserSettingsChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsIconsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsHwTypesChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsTypesChanged', eachT: 30, params: {}},
            {name: 'fetchProductsChanged', eachT: 30, params: {}},

            //{name: 'fetchAppUserChanged', eachT: 30, params: {}},

            {name: 'fetchUnitsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsLmsgsChanged', eachT: 15, params: {withAddress: 1}},
            {name: 'fetchVideoStatusesAll', eachT: 30, params: {}},

            {name: 'fetchUsersChanged', eachT: 30, params: {}},
            //{name: 'fetchUsersCustomizesChanged', eachT: 30, params: {}},

            {name: 'fetchGeopointsChanged', eachT: 30, params: {}},
            {name: 'fetchGeozonesChanged', eachT: 30, params: {}},

            {name: 'fetchUnitsKeysChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsKeysOrdersChanged', eachT: 30, params: {}},

            {name: 'fetchNotificationsChanged', eachT: 30, params: {}},

            {name: 'fetchLastEvents', eachT: 10, params: {last: '15 minutes', params: {withAddress: 1}}},
            {name: 'fetchLastVideoEvents', eachT: 10, params: {last: '15 minutes', params: {withAddress: 1}}},
            {name: 'fetchLastNotificationsEvents', eachT: 10, params: {last: '15 minutes', params: {withAddress: 1}}},

            {name: 'removeIndexToday', eachT: 10, params: {}},
        ],
    },
    api: {
        user: {
            //fields: [],
            expand: ['units_of_measure','settings','notifications_settings','is_admin','is_super_admin', 'limited','parents','dealer','dealer_apikeys','hide_menu','dealers_hide_menu','head__logo','dealer_login_logo'],
        },
        units_lite: {
            fields: ['id','hw_type','hw_id','name','isRemoved','license','icon','icon_color','parent__id'],//,'icon_halo'
            expand: [], //'access_right','user_creator', counters
        },
        units: {
            //fields: ['id','hw_type','hw_id','name','type','unit_icon','icon','max_speed','phone'],
            expand: ['user_creator','access_right', 'license'], //,counters ,'video'
        },
        users_lite: {
            //fields: [],
            expand: ['user_creator', 'is_admin', 'limited', 'access_right'],
        },
        users: {
            //fields: ['id','creator','login','email','phone','legal_name','time_zone','dst','status'],
            expand: ['user_creator', 'is_admin', 'limited', 'dealer_id', 'dealer', 'access_right'],
        },
        geopoints: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'], //'points'
        },
        geozones_lite: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'], //'points'
        },
        geozones: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right','points'], //'points'
        },

        notifications: {
            //fields: [],
            expand: ['units','units-groups','geozones','geopoints'],
        },
        notifications_lite: {
            fields: [],
            expand: ['units','units-groups','geozones','geopoints'],
        },

        keysorders: {
            // fields: ['id',],
            expand: [],
        },
        keysorders_lite: {
            fields: [
                'id','order','productId','days','key','status','days__left','time__left'
            ],//,'created','expired','removed','changed','creator','user'
            expand: [],
        },

        keys: {
            // fields: ['id',],
            expand: [],
        },
        keys_lite: {
            fields: ['id','order_id','indx','unit_id','productId','days','key','status',
                'days__left','time__left','activation_time','changed__time','is_transferred'
            ],//,'activation','created','expired','removed','changed','creator','user','unit'
            //"transfer_info","transfered_from"
            expand: [],
        },

        products: {
            // fields: ['id',],
            expand: [],
        },
    },
    components: {
        UnitEdit: {
            defaults: {
                options: {
                    components: [
                        'UnitEdit_Options_intuit',
                        'UnitEdit_Icon_intuit',
                        'UnitEdit_AddInfo_intuit',
                        'UnitEdit_Sensors_intuit',
                        // 'UnitEdit_License',
                    ]
                }
            },
            xCargoUnit: {
                options: {
                    components: [
                        'UnitEdit_Options_intuit',
                        'UnitEdit_Delivery',
                        'UnitEdit_xCargoSensors',
                        // 'UnitEdit_License',
                    ]
                }
            }
        }
    },
}

export default consts