<template>
    <header class="header">
        <div class="header__wrap">
            <div class="header__content">
                <div class="header__logo">
                    <img :src="(getAppUser.head__logo || {}).url || require('@/assets/img/empty1px.png')">
                </div>
                <ul class="header__list">
                    <li class="header__item">
                        <CheckboxControl
                            :control-class="''"
                            :label="'Dark theme'"
                            v-model="theme"
                            v-if="!$isAdminSite"
                        />
                    </li>
                    <li class="header__item">
                        <span>
                            <SvgIcon name="user" />
                        </span>
                        <span>
                            {{getAppUser.login}}
                        </span>
                    </li>
                    <li class="header__item">
                        <button class="button button_view_outline" @click="onLogout">
                            {{ $t('menu.Logout') }}
                            <SvgIcon name="common__exit" class="icon-right"/>
                        </button>
                    </li>
                    <li class="header__item">
                        <button class="button button_icon" @click="displaySectionWidget('UserSettings')">
                            <SvgIcon name="wizard-settings" class="icon" :title="$t('menu.Settings')"/>
                        </button>
                    </li>
                    <template v-if="$consts.app.$isPrometheus && $consts.app.$isPortalSite">
                        <li class="header__item header__item-line"></li>
                        <li class="header__item">
                            <span class="header__item-badge" @click="$router.push({name: $consts.routerNames.notificationsevents.main})">
                                <SvgIcon name="icon-dialog" class="icon"/>
                                <span class="header__item-badge__info" v-show="notifications">{{ notifications }}</span>
                            </span>
                            {{ $t('menu.Notifications') }}
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "SectionHeader",
    components: {},

    computed:{
        ...mapGetters([
            "getAppUser",
            "getNewEventsCount",
            "getNewNotificationEventsCount",
            "getTimeToday",
            "getLastVisitNotifications",
            "getTheme",
        ]),
        routeMeta() {
            return this.$route.meta
        },
        setRoute() {
            return this.routeMeta.setRoute
        },
        theme: {
            get() {
                return this.getTheme == this.$consts.themes.dark
            },
            set(vl) {
                this.setTheme(vl ? this.$consts.themes.dark : this.$consts.themes.white)
            }
        },
        notifications(){
            let count = this.getNewEventsCount + this.getNewNotificationEventsCount
            let plus = (this.getTimeToday > this.getLastVisitNotifications ? '+' : '')
            return (count || plus) ? count + plus : false
        },
    },
    methods:{
        ...mapMutations([
            "setSectionWidget",
            "setTheme",
        ]),
        ...mapActions([
            "logout",
        ]),

        onLogout(){
            //this.$cookies.set('authtoken', '')
            this.logout()
        },
        displaySectionWidget(component) {
            this.setSectionWidget({
                component: component,
                props: {}
            })
        },
    }
}
</script>

<style lang="scss">
.header {
    grid-area: header;
    background: var(--header-background);
    box-shadow: var(--header-box-shadow);
    position: relative;
    z-index: 6;
    &__wrap {
        padding: 10px 25px;
        height: 100%;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    &__logo {
        img{
            height: 100%;
        }
    }
    &__list {
        display: flex;
        align-items: center;
        gap: 15px;
        height: 100%;
    }
    &__item{
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        @include font(14px, 20px, 400, none, normal, var(--header-color));
    }
    &__item-line {
        height: 70%;
        width: 1px;
        background: var(--header-line-item);
    }
    &__item-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: solid 1px var(--header-border-color-notifications);
        padding: 5px;
        width: 32px;
        height: 32px;
        position: relative;
        cursor: pointer;

        &__info {
            position: absolute;
            top: 2px;
            right: 2px;
            background: var(--header-notifications-info);
            padding: 1px 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include font(8px, normal, 400, none, normal, var(--color-white));
            border-radius: 100%;
        }
    }
    .checkbox-control {
        &__text {
            @include font(14px, 20px, 400, none, normal, var(--header-color));
            @include adaptive-font(14, 12)
        }
    }
}
.prometheus-portal {
    .header {
        height: 50px;
        &__item {
            @include adaptive-font(12, 10);
            &:last-child {
                width: 180px;
            }
        }
        &__logo {
            margin-left: 90px;
            height: 100%;
        }
        .checkbox-control {
            &__text {
                @include font(12px, normal, 400, none, normal, var(--header-color));
                @include adaptive-font(12, 10)
            }
            &__switch {
                height: 1rem;
                &:before {
                    top: 1px;
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }
}
</style>