// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: '/analytics/:period?/:unitId(\\d+)?',
        name: consts.routerNames.analytics.main,
        meta: {
            mainRouteName: consts?.routerNames?.analytics?.main,
            hideMap: true,
            setRoute: {
                analytics: (route, router, unitId, period) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.analytics.main) {
                        if(this.$isDevelopment) console.warn('analytics', route);
                        return false;
                    }
                    router.push({
                        name: consts.routerNames.analytics.main,
                        params: {
                            unitId,
                            period,
                        },
                        query: {}
                    })
                }
            }
        },
        component: () => import('@/views/intuit/portal/AnalyticsPage.vue')
    },
];

export default routes;