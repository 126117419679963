<template>
    <FieldGroup__base_wrap
        class="field-group_type_select"
        :fieldGroup_class="fieldGroup_class"
        :fieldGroup_label_class="fieldGroup_label_class"
        :fieldGroup_info_class="fieldGroup_info_class"
        :id="id"
        :label="label"
        :info="info"
        :input_icon="input_icon"
        :error="error"
        :warning="warning"
        :disabled="disabled"
    >

       <div class="select-wrap" :class="selectWrap">
            <k-dropdownlist
                class=""
                :class="fieldGroup_input_class"
                :data-items="list"
                :text-field="'name'"
                :data-item-key="'id'"
                :default-item="defaultItemSelect"
                :filterable="filterable"
                :height="250"
                :value="selected"
                :disabled="disabled"
                @change="handleChange"
                @filterchange="filterChange"
            >
            </k-dropdownlist>
            <span class="field-group__icon icon"
                  @click="open"
            >
                <span class="icon__symbol"><SvgIcon class="icon" :name="icon"/></span>
            </span>
       </div>
    </FieldGroup__base_wrap>
</template>
<script>
    // import {DropDownList} from '@progress/kendo-dropdowns-vue-wrapper'
    import { filterBy } from '@progress/kendo-data-query';
    export default {
        name: 'FieldGroup_k_dropdownlist',
        emits:['update:modelValue'],
        props: {
            'fieldGroup_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_label_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_input_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'fieldGroup_info_class': {
                type: [String, Array, Object],
                default: () => ''
            },
            'selectWrap': {
                type: [String, Array, Object],
                default: () => ''
            },
            'id': String,
            'label': String,
            'modelValue': [Number, String, Array, Object],
            'placeholder': String,
            'error': {
                type: [String, Array, Object],
                default: () => ''
            },
            'warning': {
                type: [String, Array, Object],
                default: () => ''
            },
            'info': String,
            'items': [Array, Object],
            'input_icon': String,
            'field_id': {
                type: [String],
                default: () => 'id'
            },
            'field_name': {
                type: [String],
                default: () => 'name'
            },
            'sortBy': {
                type: [String, Function],
                default: (props) => props.field_name || 'name'
            },
            'disableSortBy': Boolean,
            'filterable': {
                type: Boolean,
                default: () => { return true }
            },
            icon: {
                type: String,
                default: () => { return 'input__select-2' }
            },
            'defaultItem': {
                type: [String, Boolean],
                default: () => { return '' }
            },
            'disabled': {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                filter: null,
            }
        },
        computed: {
            defaultItemSelect(){
                if(typeof this.defaultItem == 'boolean'){
                    return undefined
                } else {
                    return  {
                        name: this.defaultItem || this.$t('Select _')
                    }
                }
            },
            placeholder_text() {
                return this.placeholder ? this.placeholder :
                    this.$t('text.Enter') + ' ' + this.label.toLowerCase()
            },
            list() {
                let field_id = this.field_id
                let field_name = this.field_name
                let field_sortBy = (typeof this.sortBy === 'string') ? this.sortBy : ''
                let addFieldSortBy = (!this.disableSortBy && (typeof this.sortBy === 'string'))
                let list = []
                if (Array.isArray(this.items)) {
                    list = this.items.map(item => {
                        let listItem = {id: item[field_id], name: item[field_name]}
                        if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                        return listItem
                    })
                } else {
                    //console.warn('items isn\'t array', (typeof this.items))
                    const objectArray = Object.entries(this.items);
                    objectArray.forEach(([id, item]) => {
                        let name = item;
                        if (typeof item == 'object' && item[field_name] !== undefined) {//.hasOwnProperty
                            name = item[field_name]
                        }
                        if (typeof name == 'string') {
                            let listItem = {id, name}
                            if(addFieldSortBy) listItem.sortBy = item[field_sortBy]
                            list.push(listItem)
                        } else {
                            console.warn('item isn\'t string', id, item)
                        }
                    });
                }
                let sortBy = (typeof this.sortBy !== 'string') ? this.sortBy : 'sortBy'
                if (!this.disableSortBy) {
                    if (typeof sortBy === 'string') {
                        let field = '' + sortBy
                        sortBy = (a, b) => {
                            return (a[field] == b[field]) ? 0 :
                                (a[field] > b[field]) ? 1 : -1;
                        }
                    }
                    if (typeof sortBy === 'function') {
                        list.sort(sortBy);
                    } else {
                        console.warn('unknown method sortBy: ', typeof sortBy, sortBy)
                    }
                }
                return filterBy(list, this.filter)
            },
            selected: {
                get() { return this.list.find(l => l.id == this.modelValue) || null },
                set(v) { this.$emit('update:modelValue', v.id) }
            },
        },
        methods: {
            filterChange (event) {
                this.filter = event.filter;
            },
            handleChange(event) {
                this.selected = event.value;
            },
        },
        mounted() {
        },
        updated() {
        },
    }
</script>
